import { GridColDef } from "@mui/x-data-grid-pro";
import { IdName, Nullable, WeekDayKey } from "./common";


export type RateRuleRequestColumnField =
  | 'title'
  | 'category'
  | 'priority'
  | 'from'
  | 'to'
  | 'description'
  | 'ratePlans'
  | 'listings'
  | 'applyTo'
  | 'isActive'
  | 'actions';

export type RateRuleGridRow = {
  title: string;
  category: string;
  priority: number;
  from: Nullable<Date>;
  to: Nullable<Date>;
  description: string;
  ratePlans: RateRule['rate_plans'];
  listings: IdName[];
  applyTo: string[];
  isActive: boolean;
};
export type RateRuleGridColumn = {
  field: RateRuleRequestColumnField;
} & GridColDef;

export enum ChangePriceSpanEnum {
  INCREASE = 'Increase',
  DECREASE = 'Decrease',
};

export enum ChangePriceTypeEnum {
  PERCENT = "Percent",
  FIXED = "Fixed",
};

export enum SpanUnitEnum {
  DAY = "Day",
  HOUR = "Hour",
};

export enum MoreLessEnum {
  MORE = "More",
  LESS = "Less",
};

export type RateRuleMode = 'one_time' | 'annual';
export type RateRuleCategory = 
| "change_in_price"
| "change_in_availability"
| "early_bird_bookings"
| "last_minute_bookings"
| "orphan_nights"
| "booking_window"
| "advanced";

export enum RateRuleCategoryEnum {
  change_in_price = "CHANGE_IN_PRICE",
  change_in_availability = "CHANGE_IN_AVAILABILITY",
  early_bird_bookings = "EARLY_BIRD_BOOKINGS",
  last_minute_bookings = "LAST_MINUTE",
  orphan_nights = "ORPHAN_NIGHTS",
  booking_window = "BOOKING_WINDOW",
  advanced = "ADVANCED",
};

export type RateRuleRowKey = keyof RateRuleCreateState;

export type RateRulesParams = {

};

export type RateRuleCreateBody = {
  title: string;
  category: `${RateRuleCategoryEnum}`;
  recurring: boolean;
  startDay: Nullable<number>;
  startMonth: Nullable<number>;
  startYear: Nullable<number>;
  endDay: Nullable<number>;
  endMonth: Nullable<number>;
  endYear: Nullable<number>;
  applyToChannels: boolean,
  applyToDirectBooking: boolean,
  ratePlans: number[],
  hasConditionDaysOfWeek?: boolean,
  daysOfWeekArray?: WeekDayKey[],
  hasConditionLeadTime?: boolean,
  leadTimeSpecificTime?: Nullable<'00:00'>,
  leadTimeTimeType?: "Earliest Free Check-in Time",
  leadTimeMoreOrLess?: `${MoreLessEnum}`,
  leadTimeQuantityUnit?: `${SpanUnitEnum}`,
  leadTimeQuantityValue?: Nullable<number>,
  hasConditionOrphanNights?: boolean,
  changeRatesCapValue?: null,
  decreaseOrIncreaseType?: `${ChangePriceTypeEnum}`,
  hasActionChangeRates?: boolean,
  decreaseOrIncrease?: `${ChangePriceSpanEnum}`,
  changeRatesQuantityValue?: Nullable<number>,
  hasActionLengthOfStay?: boolean,
  minStay?: Nullable<number>,
  maxStay?: Nullable<number>,
  hasActionStopCheckins?: boolean,
  stopCheckins?: boolean,
  hasActionStopCheckouts?: boolean,
  stopCheckouts?: boolean,
  hasActionStopSell?: boolean,
  stopSell?: boolean,
}

export type SpanKey = 'month' | 'day' | 'hour';
export type SpanKeyType = {
  key: SpanKey;
  span: Nullable<number>;
};

export type PriceAdjustmentKey = 'increase_by' | 'decrease_by';
export type PriceAdjustmentKeyType = {
  key: PriceAdjustmentKey;
  percent: Nullable<string>;
};

export type RateRule = {
  id: number;
  title: string;
  category: RateRuleCategory;
  priority: number;
  disabled: boolean;
  description: Nullable<string>;
  recurring: boolean;
  start_day: number;
  start_month: number;
  start_year: number;
  end_day: number;
  end_month: number;
  end_year: number;
  has_condition_days_of_week: boolean;
  days_of_week_array: number[];
  has_condition_lead_time: Nullable<boolean>;
  lead_time_more_or_less: Nullable<string>;
  lead_time_quantity_value: Nullable<number>;
  lead_time_quantity_unit: Nullable<string>;
  has_condition_orphan_nights: Nullable<boolean>;
  has_action_change_rates: Nullable<boolean>;
  decrease_or_increase: Nullable<string>;
  decrease_or_increase_type: Nullable<string>;
  change_rates_quantity_value: Nullable<number>;
  has_action_length_of_stay: Nullable<boolean>;
  min_stay: Nullable<number>;
  max_stay: Nullable<number>;
  has_action_stop_checkins: Nullable<boolean>;
  stop_checkins: Nullable<boolean>;
  has_action_stop_checkouts: Nullable<boolean>;
  stop_checkouts: Nullable<boolean>;
  has_action_stop_sell: Nullable<boolean>;
  stop_sell: Nullable<boolean>;
  apply_to_channels: boolean;
  apply_to_direct_booking: boolean;
  rate_plans: (IdName & {
    unit_type: {
      property: IdName
    }
  })[];
};

export type RateRuleCreateState = {
  title: string;
  mode: RateRuleMode;
  timePeriod: [Nullable<Date>, Nullable<Date>];
  colour: string;
  category: RateRuleCategory;
  weekDays: WeekDayKey[];
  priceAdjustment: PriceAdjustmentKeyType;
  ends: SpanKeyType;
  starts: SpanKeyType;
  bookingWindow: SpanKeyType;
  changePrice: PriceAdjustmentKeyType;
  lengthOfStay: {
    minStay: Nullable<number>;
    maxStay: Nullable<number>;
  }
  stopSell: Nullable<boolean>;
  stopCheckins: Nullable<boolean>;
  stopCheckouts: Nullable<boolean>;
  channelBookings: boolean;
  directBookings: boolean;
  applyToAllRatePlans: boolean;
  selectedRatePlans: number[];
};

export type RateRuleState = {
  loading: boolean;
  rowCount: number;
  rateRules: RateRule[];
  errors: Record<string, string | undefined>;
} & RateRuleCreateState;