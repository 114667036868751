import React, { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import {
  Button,
  Stack,
  Typography,
  List,
  IconButton,
  Box,
  Divider,
  Link,
  Tab,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// components
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
// api
import ticketAPI from 'src/api/ticket';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Scrollbar from 'src/components/Scrollbar';
import useLocales from 'src/hooks/useLocales';
import MenuPopover from 'src/components/MenuPopover';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { HandItem } from './HandItem';
import { SkeletonMenuItem } from 'src/components/skeleton';
import { Hand } from 'src/@types/hand';
import { NewHandDrawer } from './NewHandDrawer';
import DialogBox from 'src/components/dialog-box/DialogBox';
import { useDispatch } from 'src/redux/store';
import { openDialog as openTicket } from 'src/redux/slices/ticket';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../routes/paths';

const PlusButton = styled(Button)(() => ({
  minWidth: 0,
  padding: 0,
  borderRadius: 4,
  width: 24,
  height: 24,
}));

const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} children={<></>} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

const LIMIT = 10;

export default function RaiseHand() {
  const { translate } = useLocales('common');
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const HANDS_TAB = [
    {
      value: '1',
      label: translate('all'),
    },
    {
      value: '2',
      label: translate('raise_hand.clarification_required'),
    },
  ];

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [hands, setHands] = useState<Hand[] | null>(null);
  const [error, setError] = useState(null);
  const [tab, setTabValue] = useState('1');
  const [filtersState, setFiltersState] = useState<any | null>(null);
  const [page, setPage] = useState<number>(1);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const controller = useRef(new AbortController());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDrawer = () => {
    handleClose();
    dispatch(openTicket());
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const viewAllHands = () => {
    handleClose();
    navigate(PATH_DASHBOARD.hands.list);
  };

  const getTickets = useCallback(
    async (page, limit = LIMIT, status = null) => {
      try {
        controller.current = new AbortController();
        const response = await ticketAPI.fetchTickets(
          page,
          limit,
          status,
          controller.current.signal
        );
        if (isMountedRef.current) {
          const { data, meta } = response.data;
          setHands((prev) => [...(prev ? [...prev] : []), ...data]);
          if (meta.last_page > meta.current_page) {
            setPage((prev) => prev + 1);
          }
        }
      } catch (error) {
        console.error(error);
        setError(error?.message);
      }
    },
    [isMountedRef, controller]
  );

  useEffect(() => {
    if (open) {
      getTickets(page, LIMIT, filtersState).then();
    } else {
      setHands(null);
      setPage(1);
    }

    return () => {
      controller.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTickets, tab, open]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setFiltersState(null);
    if (newValue === '2') {
      setFiltersState({
        status: [
          {
            id: 0,
            label: 'Clarification Required',
            value: 'Clarification Required',
          },
        ],
      });
    }
    setHands(null);
    setPage(1);
    setTabValue(newValue);
  };
  return (
    <div>
      <IconButtonAnimate ref={anchorRef} size="large" onClick={handleClickOpen}>
        <Iconify icon={'mdi:hand-front-right'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ p: 3 }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ mr: 0.5 }}>{translate('raise_hand.my_hands')}</Typography>
            <InfoTooltip
              arrow
              title={translate('raise_hand.learn_more') as string}
              placement={'top'}
            >
              <IconButton color="info" onClick={handleClickOpenDialog}>
                <SvgIconStyle src="/icons/ic_lamp.svg" sx={{ width: 16, height: 16 }} />
              </IconButton>
            </InfoTooltip>
          </Stack>
          <Box ml={2} p={1} flexShrink={0}>
            <Tooltip arrow title={translate('raise_hand.title') as string} placement={'top'}>
              <PlusButton variant="contained" onClick={handleClickOpenDrawer}>
                <Iconify icon={'mdi:plus'} width={24} height={24} />
              </PlusButton>
            </Tooltip>
          </Box>
        </Stack>

        <Divider />

        <TabContext value={tab}>
          <TabList variant="fullWidth" onChange={handleTabChange}>
            {HANDS_TAB.map((tab, index) => (
              <Tab key={tab.value} label={tab.label} value={String(index + 1)} />
            ))}
          </TabList>
          {hands &&
            HANDS_TAB.map((panel, index) => (
              <TabPanel key={panel.value} value={String(index + 1)}>
                <Scrollbar sx={{ maxHeight: { xs: 360 }, pb: 1 }}>
                  <List disablePadding>
                    {hands.map((ticket, index) => (
                      <HandItem key={index} hand={ticket} />
                    ))}
                  </List>
                </Scrollbar>
                <Divider />
                <Stack justifyContent={'center'} p={1}>
                  <Button onClick={viewAllHands}>{translate('raise_hand.view_all_hands')}</Button>
                </Stack>
              </TabPanel>
            ))}
        </TabContext>

        {!hands && !error && <SkeletonMenuItem />}

        {error && <Typography variant="h6">404 {error}!</Typography>}
      </MenuPopover>

      <NewHandDrawer />

      <DialogBox
        DialogProps={{
          onClose: handleCloseDialog,
        }}
        severity="info"
        icon={'ic:twotone-lightbulb'}
        action={
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Link
              target="_blank"
              rel="noopener"
              href={'https://zeevou.com/support/set-up-guide/landing-page-icons/'}
              underline={'none'}
            >
              <Button color="inherit">{translate('raise_hand.go_to_article')}</Button>
            </Link>
            <Button color="info" onClick={handleCloseDialog}>
              {translate('gotIt')}
            </Button>
          </Stack>
        }
        title={translate('raise_hand.how_to')}
        children={<Typography>{translate('raise_hand.how_to_description')}</Typography>}
        openState={openDialog}
      />
    </div>
  );
}
