import { ChangePriceSpanEnum, ChangePriceTypeEnum, MoreLessEnum, RateRuleCreateBody, RateRuleCategory, RateRuleState, SpanUnitEnum, RateRuleCategoryEnum, SpanKey } from 'src/@types/rate-rule';
import { getDate, getMonth, getYear } from 'date-fns';
import { Nullable } from 'src/@types/common';

const getSpanValue = (key: SpanKey, value: Nullable<number>) => key === 'month' ? (value ?? 0) * 30 : value;

export const convertRateRuleToRequestBody = (rateRule: RateRuleState): RateRuleCreateBody => {
  const { category } = rateRule;
  return {
    title: rateRule.title,
    category: RateRuleCategoryEnum[rateRule.category],
    recurring: rateRule.mode === 'annual',
    startDay: rateRule.timePeriod[0] ? getDate(rateRule.timePeriod[0]) : null,
    startMonth: rateRule.timePeriod[0] ? getMonth(rateRule.timePeriod[0]) + 1 : null,
    startYear:  rateRule.timePeriod[0] && rateRule.mode === 'one_time' ? getYear(rateRule.timePeriod[0]) : null,
    endDay: rateRule.timePeriod[1] ? getDate(rateRule.timePeriod[1]) : null,
    endMonth: rateRule.timePeriod[1] ? getMonth(rateRule.timePeriod[1]) + 1 : null,
    endYear:  rateRule.timePeriod[1] && rateRule.mode === 'one_time' ? getYear(rateRule.timePeriod[1]) : null,
    applyToChannels: rateRule.channelBookings,
    applyToDirectBooking: rateRule.directBookings,
    ratePlans: rateRule.selectedRatePlans,
    ...((['change_in_price', 'change_in_availability', 'orphan_nights'] as RateRuleCategory[]).includes(category) && {
      hasConditionDaysOfWeek: true,
      daysOfWeekArray: rateRule.weekDays,
    }),
    ...((['early_bird_bookings', 'last_minute_bookings', 'booking_window'] as RateRuleCategory[]).includes(category) && {
      hasConditionLeadTime: true,
      leadTimeSpecificTime: "00:00",
      leadTimeMoreOrLess: category === 'last_minute_bookings' ? MoreLessEnum.LESS : MoreLessEnum.MORE,
      ...(category === 'early_bird_bookings' && {
        leadTimeQuantityUnit: SpanUnitEnum.DAY,
        leadTimeQuantityValue: getSpanValue(rateRule.ends.key, rateRule.ends.span),
      }),
      ...(category === 'last_minute_bookings' && {
        leadTimeTimeType: "Earliest Free Check-in Time",
        leadTimeQuantityUnit: rateRule.starts.key === 'hour' ? SpanUnitEnum.HOUR : SpanUnitEnum.DAY,
        leadTimeQuantityValue: getSpanValue(rateRule.starts.key, rateRule.starts.span),
      }),
      ...(category === 'booking_window' && {
        leadTimeQuantityUnit: SpanUnitEnum.DAY,
        leadTimeQuantityValue: getSpanValue(rateRule.bookingWindow.key, rateRule.bookingWindow.span),
      }),
    }),
    ...(category === 'orphan_nights' && {
      hasConditionOrphanNights: true,
    }),
    ...((['change_in_price', 'early_bird_bookings', 'last_minute_bookings', 'orphan_nights'] as RateRuleCategory[]).includes(category) && {
      changeRatesCapValue: null,
      decreaseOrIncreaseType: ChangePriceTypeEnum.PERCENT,
      ...(category === 'change_in_price' ? {
        hasActionChangeRates: true,
        decreaseOrIncrease: rateRule.priceAdjustment.key === 'increase_by' ? ChangePriceSpanEnum.INCREASE : ChangePriceSpanEnum.DECREASE,
        changeRatesQuantityValue: +(rateRule.priceAdjustment.percent ?? ''),
      } : rateRule.changePrice.percent !== null ? {
        hasActionChangeRates: true,
        decreaseOrIncrease: rateRule.changePrice.key === 'increase_by' ? ChangePriceSpanEnum.INCREASE : ChangePriceSpanEnum.DECREASE,
        changeRatesQuantityValue: +(rateRule.changePrice.percent ?? ''),
      } : {})
    }),
    ...((['change_in_availability', 'early_bird_bookings', 'last_minute_bookings', 'orphan_nights'] as RateRuleCategory[]).includes(category) && {
      ...((rateRule.lengthOfStay.minStay !== null || rateRule.lengthOfStay.maxStay !== null) && {
        hasActionLengthOfStay: true,
        minStay: rateRule.lengthOfStay.minStay,
        maxStay: rateRule.lengthOfStay.maxStay,
      }),
      ...(rateRule.stopCheckins !== null && {
        hasActionStopCheckins: true,
        stopCheckins: rateRule.stopCheckins,
      }),
      ...(rateRule.stopCheckouts !== null && {
        hasActionStopCheckouts: true,
        stopCheckouts: rateRule.stopCheckouts,
      }),
      ...(rateRule.stopSell !== null && {
        hasActionStopSell: true,
        stopSell: rateRule.stopSell,
      }),
    }),
    ...(category === 'booking_window' && {
      hasActionStopSell: true,
      stopSell: true,
    }),
  };
};