import { Guest } from './guest';
import { GridColDef } from '@mui/x-data-grid-pro';

export type Nullable<T> = T | null;

export type ExportFileType =
  | 'text/csv'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type ImageFileExtension = 'jpg' | 'jpeg' | 'png' | 'webm';
export type FileExtension = ImageFileExtension | 'mp4' | 'pdf' | '';

export type Ratio = '4/3' | '3/4' | '6/4' | '4/6' | '16/9' | '9/16' | '21/9' | '9/21' | '1/1';

export type WeekDayKey = 'sat' | 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri';
export type MonthKey = 'jan' | 'feb' | 'mar' | 'apr' | 'may' | 'jun' | 'jul' | 'aug' | 'sep' | 'oct' | 'nov' | 'dec';

export type SyncToChannelManagerEntity = 'RatePlan' | 'Property';

export type ToolbarButtons =
  | 'font'
  | 'size'
  | 'header'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strike'
  | 'color'
  | 'background'
  | 'ordered-list'
  | 'bullet-list'
  | 'indent-minus'
  | 'indent-plus'
  | 'super-script'
  | 'sub-script'
  | 'code-block'
  | 'blockquote'
  | 'direction'
  | 'align'
  | 'link'
  | 'image'
  | 'video'
  | 'formula'
  | 'clean'
  | 'undo'
  | 'redo'
  | 'fullscreen';

export type VariableApiType =
  | 'agreement'
  | 'deposit'
  | 'payment'
  | 'automated_message'
  | 'check_in_type'
  | 'auto_response';
export type Variable = {
  name: string;
  group_name: string;
  value: string;
  example: string;
};

export type IdName = { id: number; name: string };

export type GuideConfig = {
  title?: string;
  icon?: JSX.Element | string;
  description: string;
};

export type notificatonBellType = {
  notification_id: number;
  title: string;
  description: string;
  created_at: number;
  is_read: boolean;
  redirect_link: string;
  redirect_id: number | null;
  type: string;
  is_new_tab: boolean;
};

export type Country = {
  iso2_code: string;
  iso3_code: string;
  name: string;
  continent: string;
};

// response of filterAPI-------------------------------------------------
export type Property = {
  id: number;
  is_active: boolean;
  name: string;
  short_name: string;
};
export type UnitType = {
  id: number;
  name: string;
};
// ----------------------------------------------------------------------

export type BedType =
  | 'King'
  | 'Double'
  | 'Queen'
  | 'Single'
  | 'Mattress'
  | 'Air Mattress'
  | 'Bunk Bed'
  | 'Water Bed'
  | 'Sofa'
  | 'Sofa Bed'
  | 'Crib'
  | 'Toddler Bed'
  | 'Hammock'
  | 'Bed';

export interface File {
  id: number;
  extension?: string;
  mime_type: string;
  file_name: string;
  original_file_name: string;
  file_size: number;
  created_at?: string;
  updated_at?: string;
}

export interface Image {
  id: number;
  priority: number | null;
  image_caption?: string;
  file: File;
  created_at?: any;
  updated_at?: any;
  url?: string;
}

export interface AmenityCategory {
  id: number;
  name: string;
  sort_order: number;
}

export interface Amenity {
  id: number;
  name: string;
  icon_file: File | null;
  description: string;
  icon: string;
  type?: string;
  categories?: AmenityCategory[];
}

export type Price = {
  value: number;
  currency: string;
  base_value?: number;
};

export type Currency = {
  code: string;
  symbol: string;
  name?: string;
};

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type Nationality = {
  id: number;
  nationality_name: string;
};
export type Tag = {
  id: number;
  name: string;
};

export type Region = {
  combined_code: string;
  code: string;
  name: string;
};
export type Address = {
  id: number;
  latitude: string | null;
  longitude: string | null;
  first_line: string | null;
  second_line: string | null;
  postal_code: string | null;
  city: string | null;
  region: Region | null;
  country: Country | null;
  full_address: string | null;
  complete_address: string | null;
};

export type Staff = {
  id: number;
  user: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    roles: { id: number; label: string }[];
  };
};

export type Brand = {
  id: number;
  name: string;
  sub_domain?: string;
  property_link?: string;
};

export type ApiFormat<T> = {
  data: T;
  meta: Meta;
};

export interface Meta {
  id: string;
  total_items: number;
  current_page: number;
  last_page: number;
  items_per_page: number;
}

export interface Review {
  id: number;
  booking: {
    id: number;
    booking_no: string | null;
    channel: string | null;
  } | null;
  guest: {
    id: number;
    first_name: string;
    last_name: string;
  };
  approved: boolean | null;
  feature_on_home_page: boolean | null;
  title: string | null;
  review_text: string | null;
  review_date: string | null;
  rate: number;
  channel: Channel | null;
  property: {
    id: number;
    name: string;
  };
  unit_type: {
    id: number;
    name: string;
  };
  origin: 'manual' | 'website' | 'channel';
  created_at: string;
  updated_at: string;
}

export interface UpdateReviewBody {
  title?: string | null;
  review_text?: string | null;
  review_date?: string | null;
  rate?: number;
  approved?: boolean | null;
  feature_on_home_page?: boolean | null;
  guest?: number;
  booking?: number;
}

export type ReviewsInfo = {
  review_count: number;
  review_average: number;
  channel_count: number;
};

export interface Booking {
  id: number;
  booking_code: string;
  booking_no: string;
  guest?: Guest;
  property?: {
    id: number;
    name: string;
    short_name: string;
    is_active: boolean;
  };
  arrival_date: string;
  departure_date: string;
  booking_status: string;
  unit: null;
  unit_type: {
    id: number;
    name: string;
  };
  booking_purpose: string | null;
}

export interface BaseDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export interface Channel {
  id: number;
  name: string;
  logo: string;
}

export interface BaseRatePlan {
  id: number;
  name: string;
}

export type Attachment = {
  attachment_id: number;
  file_id: number;
  file_name: string;
  original_name: string;
  mime_type: string;
  extension: string;
  file_size: number;
  created_at: string;
  owner: string;
};

export interface SEO_Setting {
  seo_friendly_title: string | null;
  meta_description: string | null;
  focus_keyword: string | null;
  facebook_title: string | null;
  facebook_description: string | null;
  facebook_image: File | null;
  twitter_title: string | null;
  twitter_description: string | null;
  twitter_image: File | null;
  meta_robots: string | null;
  follow_link_in_the_page: boolean;
  allow_show_in_search_engine: boolean;
}

export enum Condition {
  AND = 'AND',
  OR = 'OR',
  BETWEEN = 'BETWEEN',
  ON = 'ON',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  MORE_THAN = 'MORE_THAN',
  MORE_THAN_OR_EQUAL = 'MORE_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export type BooleanConditionOperator = Condition.AND | Condition.OR;
export type DateConditionOperator =
  | Condition.BETWEEN
  | Condition.ON
  | Condition.AFTER
  | Condition.BEFORE;
export type NumberConditionOperator =
  | Condition.BETWEEN
  | Condition.MORE_THAN
  | Condition.MORE_THAN_OR_EQUAL
  | Condition.LESS_THAN
  | Condition.LESS_THAN_OR_EQUAL
  | Condition.EQUAL;
export type PriorityConditionOperator = Condition.FIRST | Condition.LAST;
export type ConditionOperator =
  | BooleanConditionOperator
  | DateConditionOperator
  | NumberConditionOperator
  | PriorityConditionOperator;

export type InternalNote = {
  id: number;
  message: string;
  owner?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type GridColumn = GridColDef & { field: string };

export interface CommonResponseKeys {
  id: number;
  created_at: string | null;
  updated_at: string | null;
}

export interface ApiResponseTypeOnError {
  detail: any;
  title: string;
  type: string;
  violations: {
    message: string;
    propertyPath: string;
  }[]
}