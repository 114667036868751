
import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RateRulesParams, RateRuleState } from 'src/@types/rate-rule';
import rateRuleAPI from 'src/api/rate-rule';
import { WEEK_DAYS } from 'src/config';

import { convertRateRuleToRequestBody } from 'src/utils/rateRule';

const initialState: RateRuleState = {
  loading: false,
  rowCount: 0,
  rateRules: [],
  title: '',
  mode: 'one_time',
  timePeriod: [null, null],
  colour: '',
  category: 'change_in_price',
  weekDays: WEEK_DAYS,
  priceAdjustment: { key: 'increase_by', percent: null },
  ends: { key: 'month', span: null },
  starts: { key: 'day', span: null },
  bookingWindow: { key: 'month', span: null },
  changePrice: { key: 'increase_by', percent: null },
  lengthOfStay: {
    minStay: null,
    maxStay: null,
  },
  stopSell: null,
  stopCheckins: null,
  stopCheckouts: null,
  channelBookings: true,
  directBookings: true,
  applyToAllRatePlans: false,
  selectedRatePlans: [],
  errors: {}
};

const slice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading(state, action: PayloadAction<RateRuleState['loading']>) {
      state.loading = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.rowCount = action.payload;
    },
    setRateRules(state, action: PayloadAction<RateRuleState['rateRules']>) {
      state.rateRules = action.payload;
    },
    setRateRuleTitle(state, action: PayloadAction<RateRuleState['title']>) {
      state.title = action.payload;
      state.errors.title = undefined;
    },
    setRateRuleMode(state, action: PayloadAction<RateRuleState['mode']>) {
      state.mode = action.payload;
      state.errors.mode = undefined;
      state.errors.timePeriod = undefined;
    },
    setRateRuleTimePeriod(state, action: PayloadAction<RateRuleState['timePeriod']>) {
      state.timePeriod = action.payload;
      if (state.mode === 'one_time') {
        state.errors.timePeriod = undefined;
        state.errors['timePeriod[0]'] = undefined;
        state.errors['timePeriod[1]'] = undefined;
      }
    },
    setRateRuleColour(state, action: PayloadAction<RateRuleState['colour']>) {
      state.colour = action.payload;
      state.errors.colour = undefined;
    },
    setRateRuleCategory(state, action: PayloadAction<RateRuleState['category']>) {
      state.category = action.payload;
      state.errors.category = undefined;
    },
    setRateRuleWeekDays(state, action: PayloadAction<RateRuleState['weekDays']>) {
      state.weekDays = action.payload;
      state.errors.weekDays = undefined;
    },
    setRateRulePriceAdjustment(state, action: PayloadAction<Partial<RateRuleState['priceAdjustment']>>) {
      state.priceAdjustment = { ...state.priceAdjustment, ...action.payload };
      state.errors.priceAdjustment = undefined;
      state.errors['priceAdjustment.key'] = undefined;
      state.errors['priceAdjustment.percent'] = undefined;
    },
    setRateRuleEnds(state, action: PayloadAction<Partial<RateRuleState['ends']>>) {
      state.ends = { ...state.ends, ...action.payload };
      state.errors.ends = undefined;
      state.errors['ends.key'] = undefined;
      state.errors['ends.span'] = undefined;
    },
    setRateRuleStarts(state, action: PayloadAction<Partial<RateRuleState['starts']>>) {
      state.starts = { ...state.starts, ...action.payload };
      state.errors.starts = undefined;
      state.errors['starts.key'] = undefined;
      state.errors['starts.span'] = undefined;
    },
    setRateRuleBookingWindow(state, action: PayloadAction<Partial<RateRuleState['bookingWindow']>>) {
      state.bookingWindow = { ...state.bookingWindow, ...action.payload };
      state.errors.bookingWindow = undefined;
      state.errors['bookingWindow.key'] = undefined;
      state.errors['bookingWindow.span'] = undefined;
    },
    setRateRuleChangePrice(state, action: PayloadAction<Partial<RateRuleState['changePrice']>>) {
      state.changePrice = { ...state.changePrice, ...action.payload };
      state.errors.changePrice = undefined;
      state.errors['changePrice.key'] = undefined;
      state.errors['changePrice.percent'] = undefined;
      state.errors.actions = undefined;
    },
    setRateRuleLengthOfStay(state, action: PayloadAction<RateRuleState['lengthOfStay']>) {
      state.lengthOfStay = action.payload;
      state.errors.lengthOfStay = undefined;
      state.errors.actions = undefined;
    },
    setRateRuleStopSell(state, action: PayloadAction<RateRuleState['stopSell']>) {
      state.stopSell = action.payload;
      state.errors.stopSell = undefined;
      state.errors.actions = undefined;
    },
    setRateRuleStopCheckins(state, action: PayloadAction<RateRuleState['stopCheckins']>) {
      state.stopCheckins = action.payload;
      state.errors.stopCheckins = undefined;
      state.errors.actions = undefined;
    },
    setRateRuleStopCheckouts(state, action: PayloadAction<RateRuleState['stopCheckouts']>) {
      state.stopCheckouts = action.payload;
      state.errors.stopCheckouts = undefined;
      state.errors.actions = undefined;
    },
    setRateRuleChannelBookings(state, action: PayloadAction<RateRuleState['channelBookings']>) {
      state.channelBookings = action.payload;
      state.errors.channelBookings = undefined;
    },
    setRateRuleDirectBookings(state, action: PayloadAction<RateRuleState['directBookings']>) {
      state.directBookings = action.payload;
      state.errors.directBookings = undefined;
    },
    setRateRuleAllRatePlans(state, action: PayloadAction<RateRuleState['applyToAllRatePlans']>) {
      state.applyToAllRatePlans = action.payload;
      state.errors.applyToAllRatePlans = undefined;
    },
    setRateRuleSelectedRatePlans(state, action: PayloadAction<RateRuleState['selectedRatePlans']>) {
      state.selectedRatePlans = action.payload;
      state.errors.selectedRatePlans = undefined;
    },
    setError(state, action: PayloadAction<{ key: string; value: string | undefined }>) {
      const { key, value } = action.payload;
      state.errors = {
        ...state.errors,
        [key]: value,
      };
    },
    resetErrors(state) {
      state.errors = {};
    },
  },
});

// reducer
export default slice.reducer;
// actions
export const {
  reset,
  setRateRuleTitle,
  setRateRuleMode,
  setRateRuleTimePeriod,
  setRateRuleColour,
  setRateRuleCategory,
  setRateRuleWeekDays,
  setRateRulePriceAdjustment,
  setRateRuleEnds,
  setRateRuleStarts,
  setRateRuleBookingWindow,
  setRateRuleChangePrice,
  setRateRuleLengthOfStay,
  setRateRuleStopSell,
  setRateRuleStopCheckins,
  setRateRuleStopCheckouts,
  setRateRuleChannelBookings,
  setRateRuleDirectBookings,
  setRateRuleAllRatePlans,
  setRateRuleSelectedRatePlans,
  setError,
  resetErrors,
} = slice.actions;

// ----------------------------------------------------------

export function getRateRules(params: RateRulesParams, signal?: AbortSignal) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data: { data = [], meta } } = await rateRuleAPI.getRateRules(params, signal);
      dispatch(slice.actions.setRowCount(meta?.total_items ?? 0));
      dispatch(slice.actions.setRateRules(data));
    } catch (error) {
    }
    dispatch(slice.actions.setLoading(false));
  }
};


export function createRateRule(rateRule: RateRuleState, callback?: (response: any, succeed: boolean) => void) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const requestBody = convertRateRuleToRequestBody(rateRule);
      const response = await rateRuleAPI.createRateRule(requestBody);
      callback?.(response, true);
    } catch (error) {
      callback?.(error, false);
    }
    dispatch(slice.actions.setLoading(false));
  }
};
